<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Stock In
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitStockInForm">
      <validation-observer
        ref="inventoryItemCreateForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="DownloadIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Stock-In List
            </h3>
          </template>
          <b-row
            v-if="!isMobile()"
            style="margin-bottom: 6px"
          >
            <b-col
              lg="7"
              md="7"
              sm="4"
            >Item(s)
            </b-col>
            <b-col
              lg="2"
              md="2"
              sm="4"
            >Store</b-col>
            <b-col
              lg="2"
              md="2"
              sm="4"
            >Quantity</b-col>
          </b-row>
          <div v-if="stockItems.length > 0">
            <b-row
              v-for="(stockItem, index) in stockItems"
              :id="'stockItems' + index"
              :key="index"
            >
              <b-col
                lg="7"
                md="7"
                sm="12"
              >
                <span v-if="isMobile()">Item(s)</span>
                <div
                  class="d-flex justify-content-between"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required"
                    style="width: 100%"
                  >
                    <b-form-group
                      :label-for="'itemName'+ index"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <b-form-input
                        :id="'itemName'+ index"
                        v-model="stockItem.name"
                        name="name"
                        :state="(errors.length > 0) ? false : null"
                        placeholder="Item name"
                        disabled
                        class="disabled-clear"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <b-img
                    class="item-img-block"
                    :src="stockItem.image ? stockItem.image : defaultImage"
                  />
                </div>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-row
                  v-for="(storeData, storeIndex) in stockItem.stores"
                  :key="storeIndex"
                >
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <span v-if="isMobile()">Store</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Store"
                      :vid="`store_${index}_${storeIndex}`"
                      rules="required"
                    >
                      <b-form-group
                        :label-for="`store_${index}_${storeIndex}`"
                        class="mb-0"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <v-select
                          :id="`store_${index}_${storeIndex}`"
                          v-model="storeData.store"
                          label="name"
                          placeholder="Select a store"
                          :options="permittedDataOptions"
                          :reduce="name => name._id"
                          :clearable="false"
                          @input="stockStoreValidation(index, storeIndex, storeData.store)"
                        >
                          <!-- eslint-disable-next-line vue/no-template-shadow -->
                          <template #option="{ name, department }">
                            <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                            <br>
                            <span
                              style="font-weight: 500;font-size: 12px;"
                              class="text-muted"
                            >
                              {{ department.name }}
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    lg="5"
                    md="5"
                    sm="12"
                  >
                    <span
                      v-if="isMobile()"
                      class="mt-50 d-block"
                    >
                      Quantity
                    </span>
                    <div class="store-options">
                      <validation-provider
                        #default="{ errors }"
                        name="Quantity"
                        :vid="`quantity_${index}_${storeIndex}`"
                        rules="required|min_value:1|numeric"
                        style="width: 100%;"
                      >
                        <b-form-group
                          :label-for="`quantity_${index}_${storeIndex}`"
                          :class="{ 'is-invalid': errors.length }"
                        >
                          <b-form-input
                            :id="`quantity_${index}_${storeIndex}`"
                            v-model="storeData.quantity"
                            name="quantity"
                            placeholder="0"
                            @keypress="validateNumberInput"
                            @paste="validateNumberPaste"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    lg="1"
                    md="1"
                    sm="12"
                    :class="{'p-0':!isMobile()}"
                  >
                    <div
                      v-if="stockItem.stores.length > 1"
                    >
                      <b-button
                        variant="flat-primary"
                        class="pl-0 primary-button-with-background no-background"
                        type="button"
                        @click="removeStoreData(index,storeIndex)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25 trash-icon"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="none"
                  class="text-primary add-more-store-btn"
                  :class="{'mb-0':isMobile(), 'mb-2':!isMobile()}"
                  @click="addMoreStore(index)"
                >
                  Add more store
                </b-button>
              </b-col>
              <b-col
                md="1"
                lg="1"
                sm="12"
                :class="{'p-0':!isMobile()}"
              >
                <b-button
                  variant="flat-primary"
                  class="pl-0 primary-button-with-background no-background"
                  type="button"
                  @click="removeStockItem(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-25 trash-icon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              lg="7"
              md="7"
              sm="12"
            >
              <span v-if="isMobile()">Item(s)</span>
              <div
                class="d-flex justify-content-between search-bar"
              >
                <b-form-input
                  id="search_name"
                  v-model="searchInput"
                  label="Item(s)"
                  name="name"
                  placeholder="Search item"
                  autocomplete="off"
                  @keyup="submitSearchInput()"
                />
                <b-img
                  class="item-img-block"
                  :src="defaultImage"
                />
              </div>
              <div v-show="searchInputResult">
                <div
                  class="search-results mt-50"
                >
                  <div>
                    <b-list-group
                      v-if="storeItemSearchInputData.length"
                      flush
                    >
                      <span class="found-text-block">Inventory items found in this store</span>
                      <b-list-group-item
                        v-for="(storeItem, sIndex) in storeItemSearchInputData"
                        :key="sIndex"
                        class="cursor-pointer"
                        @click="addSearchInputStoreItem(sIndex, storeItem.item._id)"
                      >
                        <div class="d-flex">
                          <b-img
                            class="item-img-block"
                            :src="storeItem.item.image ? storeItem.item.image : defaultImage"
                          />
                          <div
                            class="search-item-text-block"
                          >
                            <h6>{{ storeItem.item.name }}</h6>
                            <small class="text-muted">{{ storeItem.item.sku }}</small>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <b-list-group
                      v-if="masterItemSearchInputData.length"
                      flush
                      class="mt-1"
                    >
                      <span class="found-text-block">Inventory items found in Master list</span>
                      <b-list-group-item
                        v-for="(masterItem, mIndex) in masterItemSearchInputData"
                        :key="mIndex"
                        class="cursor-pointer"
                        @click="addSearchInputMasterItem(mIndex, masterItem._id)"
                      >
                        <div class="d-flex">
                          <b-img
                            class="item-img-block"
                            :src="masterItem.image ? masterItem.image : defaultImage"
                          />
                          <div
                            class="search-item-text-block"
                          >
                            <h6>{{ masterItem.name }}</h6>
                            <small class="text-muted">{{ masterItem.sku }}</small>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
              </div>
              <b-card
                v-show="searchResultNotFound"
                no-body
                class="mt-50"
              >
                <div class="result-not-found-block">
                  <h5>No inventory item found.</h5>
                  <b-button
                    v-if="canViewThisAction('create', 'InventoryItem')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    type="button"
                    @click="showRegisterModal"
                  >
                    Register New Item
                  </b-button>
                </div>
              </b-card>
            </b-col>
            <b-col
              lg="4"
              md="4"
              sm="4"
            >
              <b-row>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Store
                  </span>
                  <v-select
                    id="search_store"
                    v-model="store"
                    label="name"
                    placeholder="Select a store"
                    :options="permittedDataOptions"
                    :reduce="s => s._id"
                    :clearable="false"
                    @input="submitSearchInput()"
                  >
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #option="{ name, department }">
                      <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                      <br>
                      <span
                        style="font-weight: 500;font-size: 12px;"
                        class="text-muted"
                      >
                        {{ department.name }}
                      </span>
                    </template>
                  </v-select>
                </b-col>
                <b-col
                  lg="5"
                  md="5"
                  sm="12"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Quantity
                  </span>
                  <b-form-input
                    id="search_quantity"
                    v-model="quantity"
                    name="quantity"
                    placeholder="0"
                    @keypress="validateNumberInput"
                    @paste="validateNumberPaste"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <div class="d-flex justify-content-between">
            <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length ? stockItems.length : '-' }}</p>
            <p><span class="text-bold-black">Total Quantity</span> <span class="text-success ml-75">+{{ stockInTotalQuantity }}</span></p>
          </div>
        </b-card>
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Stock In Remarks
            </h3>
          </template>
          <b-row>
            <b-col md="12">
              <b-form-group
                label=""
                label-for="remarks"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Minimum Stock"
                  vid="minimum-stock"
                  rules=""
                >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    :state="(errors.length > 0) ? false : null"
                    name="remarks"
                    rows="5"
                    placeholder="Any other information for the stock in"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li><span class="ml-2">Stock In by <b>{{ user.name }}</b> on {{ dateFormatWithTime(new Date()) }}</span></li>
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'inventory-stock-list', query: { store: storeOriginal } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-stock-in-items
              type="button"
              variant="success"
              :disabled="invalid || stockItems.length < 1"
              @click="stockInAllItemsPreview()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span class="align-middle">Confirm</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
    <!-- add new master item -->
    <b-modal
      id="modal-register-new-item"
      ref="register-new-item-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Register new Inventory Item in Master List"
      @ok="submitRegisterNewItem"
      @cancel="closeRegisterNewItemModal"
    >
      <b-form @submit.prevent="submitRegisterNewItem">
        <validation-observer
          ref="registerNewItemModalForm"
        >
          <b-form-group
            label="Item Name*"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                :state="(errors.length > 0) ? false : null"
                name="name"
                placeholder="Item Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="nameValidation"
                class="text-danger"
              >
                {{ nameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="SKU*"
            label-for="sku"
          >
            <validation-provider
              #default="{ errors }"
              name="SKU"
              vid="sku"
              rules="required"
            >
              <b-form-input
                id="sku"
                v-model="sku"
                :state="(errors.length > 0) ? false : null"
                name="sku"
                placeholder="Input SKU number, e.g. URN0-LAVENDER-CRST-LIULI"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="skuValidation"
                class="text-danger"
              >
                {{ skuError }}
              </small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="category"
            rules=""
          >
            <b-form-group
              label="Category"
              label-for="category"
              :state="(errors.length > 0) ? false : null"
            >
              <v-select
                id="category"
                v-model="category"
                label="name"
                placeholder="Select from list"
                :options="categories"
                :reduce="name => name._id"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="categoryValidation"
                class="text-danger"
              >
                {{ categoryError }}
              </small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="brand"
            rules=""
          >
            <b-form-group
              label="Brand"
              label-for="brand"
              :state="(errors.length > 0) ? false : null"
            >
              <b-form-input
                id="brand"
                v-model="brand"
                :state="(errors.length > 0) ? false : null"
                name="brand"
                placeholder="Brand Name"
              />

              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="brandValidation"
                class="text-danger"
              >
                {{ brandError }}
              </small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <feather-icon
            icon="SaveIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Register</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ add new master items -->
    <!-- stock in items modal -->
    <b-modal
      id="modal-stock-in-items"
      ref="stock-in-items-modal"
      modal-class="status-update-modal"
      size="lg"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitStockInForm"
      @cancel="closeStockInItemsModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <feather-icon
            icon="DownloadIcon"
            style="color: #21BB8B; margin-bottom: 7px;"
            size="60"
          />
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000;">
              Proceed with Stock-In?
            </h4>
            <p style="color: #333333;">
              Please review the inventory item(s) below to be stocked-in.
            </p>
          </div>
        </div>
      </template>
      <b-table-simple
        responsive
        sticky-header
        class="shadow-table"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Store
            </b-th>
            <b-th style="height: 0px !important;">
              Quantity
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(stockInItem, stockIndex) in stockInPreviewItems"
            :key="stockIndex"
          >
            <b-td>
              <div class="d-flex">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockInItem.image ? stockInItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockInItem.name || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockInItem.sku || '-' }}</span>
                </div>
              </div></b-td>
            <b-td>
              {{ resolveStoreName(stockInItem.store) }}
            </b-td>
            <b-td>
              <span
                class="text-success"
              >+{{ stockInItem.quantity }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length }}</p>
        <p><span class="text-bold-black">Total Quantity</span> <span class="text-success ml-75">+{{ stockInTotalQuantity }}</span></p>
      </div>
      <hr class="mt-0">
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          No, go back
        </b-button>
        <b-button
          variant="primary"
          type="button"
          @click="ok()"
        >
          <span class="align-middle">Yes, proceed</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ stock in items modal -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BImg, BListGroup, BListGroupItem, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationContentCustom from './ToastificationContentCustom.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BImg,
    BListGroup,
    BListGroupItem,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      saveStockIn: false,
      searchInput: '',
      storeOriginal: localStorage.getItem('storeVal'),
      store: localStorage.getItem('storeVal'),
      user: store.state.auth.userData,
      quantity: '',
      remarks: '',
      remarksError: 'Valid Remarks is required',
      remarksValidation: false,
      storeDataOptions: [],
      permittedDataOptions: [],
      stockItems: [],
      storeItemData: [],
      masterItemData: [],
      selectStockItem: JSON.parse(localStorage.getItem('items')) || [],
      storeItemSearchInputData: [],
      masterItemSearchInputData: [],
      searchInputResult: false,
      searchResultNotFound: false,
      stockInPreviewItems: [],
      // register new item
      name: '',
      sku: '',
      category: '',
      brand: '',
      nameError: 'Valid Name is required',
      nameValidation: false,
      skuError: 'Valid SKU is required',
      skuValidation: false,
      categoryError: 'Valid Category is required',
      categoryValidation: false,
      brandError: 'Valid Brand is required',
      brandValidation: false,
      // validation rules
      required,
    }
  },
  computed: {
    stockInTotalQuantity() {
      const allStockItems = this.stockItems.length
      if (allStockItems) {
        let initStockQuantity = 0
        this.stockItems.forEach(item => {
          if (item.stores.length) {
            item.stores.forEach(storeData => {
              initStockQuantity -= -storeData.quantity
            })
          }
        })
        return initStockQuantity
      }
      return 0
    },
  },
  beforeMount() {
    this.$http.get('inventory/category/respond-with/name-and-id')
      .then(response => {
        this.categories = response.data.categories ?? []

        // this.category = this.categories[0]._id ?? ''
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saveStockIn) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          localStorage.removeItem('storeVal')
          localStorage.removeItem('items')
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  created() {
    this.$http.get('inventory/stock/respond-with/store-and-item')
      .then(response => {
        this.storeDataOptions = response.data.stores
        this.masterItemData = response.data.items
        this.storeItemData = response.data.storeItems
        const allItems = response.data.items
        if (this.selectStockItem.length) {
          this.selectStockItem.forEach(selectedItem => {
            const item = allItems.find(itemObj => itemObj._id === selectedItem.item)
            if (item) {
              this.stockItems.push({
                item: item._id,
                name: item.name,
                image: item.image,
                sku: item.sku,
                stores: [{
                  store: this.store,
                  quantity: this.quantity,
                }],
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${selectedItem.itemName} was deleted.` ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
        }

        this.permittedDataOptions = []
        this.storeDataOptions.forEach(iStore => {
          const filteredArray = iStore.roles.filter(o1 => this.user.roles.some(o2 => o1._id === o2._id))
          const foundPermission = filteredArray.filter(o => o.permissions.find(i => i.action === 'stock-in'))
          if (foundPermission.length) {
            this.permittedDataOptions.push(iStore)
          }
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    addMoreStore(index) {
      this.stockItems[index].stores.push({
        store: '',
        quantity: '',
      })
    },
    removeStoreData(index, storeIndex) {
      this.stockItems[index].stores.splice(storeIndex, 1)
    },
    removeStockItem(stockIndex) {
      this.stockItems.splice(stockIndex, 1)
    },
    showRegisterModal() {
      this.name = this.searchInput
      this.$root.$emit('bv::show::modal', 'modal-register-new-item', '')
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    stockStoreValidation(stockItemIndex, storeIndex, storeId) {
      const selectedStoreFound = this.stockItems[stockItemIndex].stores.filter(obj => obj.store === storeId)
      if (selectedStoreFound.length > 1) {
        this.stockItems[stockItemIndex].stores[storeIndex].store = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This store is already selected in this selected item, please select another store.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.stockItems[stockItemIndex].stores[storeIndex].store = storeId
      }
    },
    submitSearchInput() {
      this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
      this.masterItemSearchInputData = this.masterItemData.filter(item => item.name.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase()))
      if (this.searchInput !== '') {
        this.searchInputResult = true
      } else {
        this.searchInputResult = false
      }
      if (!this.storeItemSearchInputData.length && !this.masterItemSearchInputData.length) {
        this.searchResultNotFound = true
      } else {
        this.searchResultNotFound = false
      }
    },
    addSearchInputStoreItem(index, id) {
      if (this.storeItemData.length) {
        const item = this.storeItemData.find(storeItemObj => storeItemObj.item._id === id)
        if (this.stockItems.length) {
          const foundItemId = this.stockItems.find(obj => obj.item === item.item._id)
          if (foundItemId) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'This item is already added!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.stockItems.push({

              item: item.item._id,
              name: item.item.name,
              image: item.item.image,
              sku: item.item.sku,
              stores: [{
                store: this.store,
                quantity: this.quantity,
              }],
            })
            this.quantity = ''
            this.searchInput = ''
            this.searchInputResult = false
            this.storeItemSearchInputData.splice(index, 1)
          }
        } else {
          this.stockItems.push({

            item: item.item._id,
            name: item.item.name,
            image: item.item.image,
            sku: item.item.sku,
            stores: [{
              store: this.store,
              quantity: this.quantity,
            }],
          })
          this.quantity = ''
          this.searchInput = ''
          this.searchInputResult = false
          this.storeItemSearchInputData.splice(index, 1)
        }
      }
    },
    addSearchInputMasterItem(index, id) {
      if (this.masterItemData.length) {
        const item = this.masterItemData.find(masterItemObj => masterItemObj._id === id)
        if (this.stockItems.length) {
          const foundItemId = this.stockItems.find(obj => obj.item === item._id)
          if (foundItemId) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'This item is already added!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.stockItems.push({

              item: item._id,
              name: item.name,
              image: item.image,
              sku: item.sku,
              stores: [{
                store: this.store,
                quantity: this.quantity,
              }],
            })
            this.searchInput = ''
            this.searchInputResult = false
            this.masterItemSearchInputData.splice(index, 1)
          }
        } else {
          this.stockItems.push({

            item: item._id,
            name: item.name,
            image: item.image,
            sku: item.sku,
            stores: [{
              store: this.store,
              quantity: this.quantity,
            }],
          })
          this.searchInput = ''
          this.searchInputResult = false
          this.masterItemSearchInputData.splice(index, 1)
        }
      }
    },
    closeRegisterNewItemModal() {
      this.$root.$emit('bv::hide::modal', 'modal-register-new-item', '')
    },
    closeStockInItemsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-stock-in-items', '')
      this.stockInPreviewItems = []
    },
    resolveStoreName(storeId) {
      const storeData = this.storeDataOptions.find(s => s._id === storeId)
      if (storeData) {
        return storeData.name
      }
      return ''
    },
    stockInAllItemsPreview() {
      this.stockInPreviewItems = []
      this.stockItems.forEach(stockItem => {
        stockItem.stores.forEach(storeData => {
          this.stockInPreviewItems.push({
            item: stockItem.item,
            name: stockItem.name,
            image: stockItem.image,
            sku: stockItem.sku,
            store: storeData.store,
            quantity: storeData.quantity,
          })
        })
      })
    },
    submitRegisterNewItem(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.registerNewItemModalForm.validate().then(success => {
        if (success) {
          const cat = this.categories.find(o => o._id === this.category)
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('category', this.category)
          formData.append('categoryTitle', cat ? cat.name : '')
          formData.append('sku', this.sku)
          formData.append('brand', this.brand)
          formData.append('minStock', 0)
          formData.append('image', null)
          this.$http.post('inventory/item/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.searchInput = ''
              this.searchInputResult = false
              this.searchResultNotFound = false
              this.closeRegisterNewItemModal()
              const item = response.data.data
              this.masterItemData.push(item)
              this.stockItems.push({
                item: item._id,
                name: item.name,
                image: item.image,
                sku: item.sku,
                stores: [{
                  store: this.store,
                  quantity: this.quantity,
                }],
              })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              // clear form data
              this.name = ''
              this.category = ''
              this.brand = ''
              this.sku = ''
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'category') {
                    this.categoryError = validationError.msg
                    this.categoryValidation = true
                  } else if (validationError.param === 'sku') {
                    this.skuError = validationError.msg
                    this.skuValidation = true
                  } else if (validationError.param === 'brand') {
                    this.brandError = validationError.msg
                    this.brandValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitStockInForm() {
      const formData = new FormData()
      const itemsArray = []
      const storesArray = []
      let storeList = ''
      this.stockItems.forEach(item => {
        itemsArray.push(item.item)
        item.stores.forEach(storeData => {
          const exists = storesArray.find(obj => obj.store === storeData.store)
          if (exists) {
            const itemArray = {
              item: item.item,
              quantity: +storeData.quantity,
            }
            exists.totalItems += 1
            exists.totalQuantity -= -storeData.quantity
            exists.items.push(itemArray)
          } else {
            const itemArray = {
              item: item.item,
              quantity: +storeData.quantity,
            }
            const storeArr = {
              store: storeData.store,
              items: [itemArray],
              totalItems: 1,
              totalQuantity: +storeData.quantity,
            }

            storesArray.push(storeArr)
          }
          const found = this.storeDataOptions.find(obj => obj._id === storeData.store)
          if (found) {
            if (storeList === '') {
              storeList += `${found.name} (${found.department.name})`
            } else {
              storeList += `, ${found.name} (${found.department.name})`
            }
          }
        })
      })
      formData.append('items', JSON.stringify(itemsArray))
      formData.append('stores', JSON.stringify(storesArray))
      // formData.append('totalItems', this.stockItems.length)
      // formData.append('totalQuantity', this.stockInTotalQuantity)
      formData.append('remarks', this.remarks)
      this.$http.post('inventory/stock/stock-in', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          localStorage.removeItem('storeVal')
          localStorage.setItem('storeOperation', 'stock-in')
          localStorage.setItem('items', JSON.stringify(this.stockItems))
          // this.stockItems.forEach(item => {
          //   item.stores.forEach(storeData => {
          //     const found = this.storeDataOptions.find(obj => obj._id === storeData.store)
          //     if (found) {
          //       if (storeList === '') {
          //         storeList += `${found.name} (${found.department.name})`
          //       } else {
          //         storeList += `, ${found.name} (${found.department.name})`
          //       }
          //     }
          //   })
          // })
          const toastMessage = `<span class="text-bold-black">${this.stockItems.length} inventory item(s)</span> has been stocked-in to these store(s): <span class="text-bold-black">${storeList}</span>.`
          // this.closeRegisterNewItemModal()
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'Stock-In Successful',
              text: toastMessage,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.saveStockIn = true
          this.$router.push({ name: 'inventory-stock-list', query: { store: this.storeOriginal } })
          // this.$swal({
          //   title: response.data.message ?? '',
          //   html: '',
          //   // eslint-disable-next-line global-require
          //   imageUrl: require('@/assets/images/icons/success.png'),
          //   imageWidth: 80,
          //   imageHeight: 80,
          //   imageAlt: 'Custom Icon',
          //   showCancelButton: false,
          //   confirmButtonText: 'Okay',
          //   customClass: {
          //     confirmButton: 'btn btn-primary',
          //   },
          //   buttonsStyling: false,
          // })
          //   .then(result => {
          //     this.saveStockIn = true
          //     if (result.value) {
          //       this.$router.push({ name: 'inventory-stock-list' })
          //     }
          //   })
        })
        .catch(error => {
          if (error.data.errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.errors[0].msg ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>

<style scoped>
  .item-img-block{
    box-sizing: border-box;
    width: 38px;
    height: 38px;
    border: 1px solid #D2DCEA;
    border-radius: 5px;
    margin-left: 2px;
  }
  .trash-icon{
     cursor: pointer;
  }
  .search-bar{
     position: relative;
  }
  .search-results{
    width: 97.5%;
    position: absolute;
    top: 5;
    z-index: 1;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(0, 0, 0, 0.0001);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow-y: auto;
    max-height: 340px;
  }
  .result-not-found-block{
    width: 100%;
    position: absolute;
    top: 5;
    left: 0;
    z-index: 1;
    text-align: center;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(0, 0, 0, 0.0001);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px 0;
  }
  .found-text-block{
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      padding: 15px 30px 5px 22px;
  }
  .store-options{
    display: flex;
    justify-content: space-between;
  }
  .add-more-store-btn {
    margin-left: -14px;
    margin-top: -13px;
  }
  /* stock in items modal */
  .stock-items-modal-header{
    margin: 20px auto 0px auto;
    text-align: center;
  }
  .img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
  /* ./ stock in items modal */
</style>
