import { render, staticRenderFns } from "./StockIn.vue?vue&type=template&id=3625bcac&scoped=true&"
import script from "./StockIn.vue?vue&type=script&lang=js&"
export * from "./StockIn.vue?vue&type=script&lang=js&"
import style0 from "./StockIn.vue?vue&type=style&index=0&id=3625bcac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3625bcac",
  null
  
)

export default component.exports